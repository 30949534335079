/* eslint-disable max-len */

import { useRouter } from 'next/router';
import Script from 'next/script';

import { useComplianceLevelState, useFeatureFlagsState } from '@/hooks/store';
import { NO_RESTRICTIONS, REQUIRE_GDPR_MODAL } from '@/store/complianceLevel/types';
import { FEATURE_FLAGS } from '@/store/feature-flags/types';

const LayoutScripts = () => {
	const complianceLevel = useComplianceLevelState();
	const featureFlags = useFeatureFlagsState();
	const router = useRouter();
	// once we move over to Auth0, we may be able to remove this code.
	// this is here because it is impossible to prevent sensitive path params, like reset tokens,
	// ...from leaking to these analytics providers.
	const isPasswordResetPage = router.pathname?.includes('/password-reset/');

	return (
		<>
			<script
				src="https://truemed-public.s3.us-west-1.amazonaws.com/truemed-ads/prequal-widget.min.js"
				defer
			></script>
			{complianceLevel !== REQUIRE_GDPR_MODAL && !isPasswordResetPage && (
				<Script id="segment">
					{`!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="UkAbwIzeCAT1CSDFIDFq98KJufcc6Frr";;analytics.SNIPPET_VERSION="4.15.3";
						analytics.load("${process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY}");
						}}();`}
				</Script>
			)}
			<Script id="browser-warning-init">
				{`
          var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
          // true on IE11
          // false on Edge and other IEs/browsers.
          if (isIE11) {
            window.location = '/_n/browserwarning.html?browser=ie11';
          }
        `}
			</Script>
			{(complianceLevel === NO_RESTRICTIONS ||
				(complianceLevel === REQUIRE_GDPR_MODAL &&
					!featureFlags[FEATURE_FLAGS.GDPR_OPT_OUT_BY_DEFAULT_FLAG])) &&
				!isPasswordResetPage && (
					<>
						<Script id="gtm-init">
							{`
          dataLayer = [];
		  function gtag() { dataLayer.push(arguments); }
		  gtag('consent', 'default', {
			'ad_user_data': 'granted',
			'ad_personalization': 'granted',
			'ad_storage': 'granted',
			'analytics_storage': 'granted'
		  });
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_CONTAINER}');
        `}
						</Script>
						<noscript>
							<iframe
								title="gtm-body"
								src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_CONTAINER}`}
								height="0"
								width="0"
								style={{ display: 'none', visibility: 'hidden' }}
							/>
						</noscript>
					</>
				)}
			{process.env.NEXT_PUBLIC_CALM_ENV === 'production' && complianceLevel !== REQUIRE_GDPR_MODAL && (
				<Script id="px-init" strategy="afterInteractive">
					{`
            (function(){
              window._pxAppId = '${process.env.NEXT_PUBLIC_PX_APP_ID}';
              var p = document.getElementsByTagName('script')[0],
                  s = document.createElement('script');
              s.async = 1;
              s.src = '${process.env.NEXT_PUBLIC_API_ENDPOINT}/12Xk43jk/init.js';
              p.parentNode.insertBefore(s,p);
            }());
          `}
				</Script>
			)}
			{complianceLevel === NO_RESTRICTIONS && (
				<Script id="schema-init" type="application/ld+json">
					{`
            {
              "@context": "http://schema.org",
              "@type": "Organization",
              "url": "https://www.calm.com",
              "logo": "https://www.calm.com/_n/images/calm-logo-gradient.png"
            }
          `}
				</Script>
			)}
		</>
	);
};

export default LayoutScripts;
